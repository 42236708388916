<template>
    <div class="card shadow">
        <div class="card-body  p-sm-0">
            <div class="d-flex justify-content-between align-items-center">
                <h4 class="card-title mb-0 ml-0">My modules</h4>
                <div class="d-flex align-items-baseline">
<!--                  <button @click="openModuleAddModal" class="card-title btn btn-text">Add Modules</button>-->
                  <router-link
                      class="card-title"
                      :to="{name: 'MenuDesign', params:{moduleId: moduleId, companyId:companyId} }"
                  >Menu Design</router-link>
                </div>
            </div>
            <hr>
        </div>
        <div class="card-body p-sm-0">
            <div class="row text-center" style="margin: 0 auto;">
                <template v-for="(item, index) in modules" :key="index">
                    <div v-if="item.status == 'active'" class="col-sm-6 col-md-2 py-2 m-1 pageCard">
                        <Module
                            :item="item"
                        />
                    </div>
                </template>
            </div>
        </div>

      <ModuleAddModal
          ref="moduleAddModal"
          title="Add Modules"
          @onClickSubmit="handleModuleAddModalSubmission"
      />

    </div>
</template>

<script>
import Module from '@/components/molecule/company/company-setting/Module'
import handleModules from "../../../../services/modules/modules";
import ModuleAddModal from "../ModuleAddModal";
import {inject} from "vue";

export default {
    name: 'ModuleCard',
    components: {
        Module,
        ModuleAddModal,
    },
    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        moduleId() {
            return this.$route.params.moduleId
        }
    },
    props: {
        modules: {
            required: true
        }
    },
    methods:{
      openModuleAddModal() {
        this.$refs.moduleAddModal.toggleModal();
      },

      async handleModuleAddModalSubmission(formData) {
        try {
          const res = await this.saveCompaniesModules(formData);

          if (res.status) {
            this.openModuleAddModal();
            this.showSuccess(res.message);
          } else {
            this.showError(res.message);
          }
        } catch(err) {
          this.showError(err);
        }
      }
    },

    setup() {
      const showError =  inject('showError');
      const showSuccess =  inject('showSuccess');

      const {
        saveCompaniesModules,
      } = handleModules();

      return {
        showError,
        showSuccess,
        saveCompaniesModules,
      }
    }
}
</script>

<style scoped>
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid rgba(34, 41, 47, 0.125);
        border-radius: 15px;
    }
    .card-body{
        padding: 0;
        border-radius: 15px 15px 0 0 !important;
    }
    .card-title{
        background-color: #fff;
        margin: 0;
        padding:15px 15px;
        color: #7D7D7D;
        border-radius: 15px 15px 0 0;
        /* border-bottom: .5px solid #7D7D7D; */
    }
      .pageCard{
        padding: 10px;
        margin: 10px !important;
        box-sizing: border-box;
        border-radius: 10px;
        /* background-color: #DBE7F2; */
        box-sizing: border-box !important;

    }
    .pageCard:hover{
        padding: 10px;
        margin: 10px !important;
        background-color: #DBE7F2;
        box-sizing: border-box !important;

    }
    .pageCard p{
        display: -webkit-box;
        width: 130px;
        height: 40px;
        color: #1B73E6;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.625;
    }
    hr{
        margin: 0;
    }
    @media screen and (max-width: 400px){
        .pageCard{
            width: 50%;
        }
         .pageCard{
        padding: 0px;
        margin: 0px !important;
        /* background-color: #DBE7F2; */
        box-sizing: border-box !important;

    }
    .pageCard:hover{
        padding: 0px;
        margin: 0px !important;
        background-color: #DBE7F2;
        border-radius: 10px;
        box-sizing: border-box !important;
    }
    /* .pageCard:hover>.avatar {
        border: 100px solid red;
    } */
    }

</style>
