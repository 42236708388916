import Network from "../network";

export default function handleModules() {
    const network = new Network;

    const saveCompaniesModules = (data) => {
        return network.api('post', '/company/modules/store', data);
    }

    return {
        saveCompaniesModules,
    }
}