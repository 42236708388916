<template>
    <div class="">
            <div class="avatar mb-1">
            <div  class="avatar-content">
                <img :src="item.icon" alt="" width="40">
            </div>
        </div>
        <!-- <h2 class="fw-bolder">{{ item.status }}</h2> -->
        <p class="card-text">{{ item.name.slice(0,28) }}{{item.name.length>28 ? '...':''}}</p>
    </div>
</template>

<script>
export default {
    name: 'Module',
    props: {
        item: Object
    }
}
</script>
