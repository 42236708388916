<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{title}}</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                <div class="pb-2">
                    <!-- form -->
                    <form onsubmit="return false" novalidate="novalidate">
                        <div class="row gy-1 px-2">
                            <div class="col-12">
                                <label class="form-label" for="chartName">Name</label>
                                <div class="input-group input-group-merge">
                                    <input
                                        v-model="companyData.name"
                                        id="chartName"
                                        class="form-control add-credit-card-mask" 
                                        type="text"
                                        placeholder="Account Name"
                                    >
                                    <span class="input-group-text cursor-pointer p-25" >
                                        <span class="add-card-type"></span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="email">Email</label>
                                <input
                                type="text"
                                id="email"
                                class="form-control"
                                placeholder="email"
                                v-model="companyData.email"
                                />
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="phone">Phone</label>
                                <input
                                type="text"
                                id="phone"
                                class="form-control"
                                placeholder="phone"
                                v-model="companyData.phone"
                                />
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="vat_no">Vat No.</label>
                                <input
                                    type="text"
                                    id="vat_no"
                                    class="form-control"
                                    placeholder="Vat Number"
                                    v-model="companyData.vat_no"
                                />
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="tin_no">TIN No.</label>
                                <input
                                    type="text"
                                    id="tin_no"
                                    class="form-control"
                                    placeholder="TIN Number"
                                    v-model="companyData.tin_no"
                                />
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="reg_no">Reg No.</label>
                                <input
                                    type="text"
                                    id="reg_no"
                                    class="form-control"
                                    placeholder="Reg Number"
                                    v-model="companyData.reg_no"
                                />
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="phone">Address</label>
                                <textarea
                                type="text"
                                id="phone"
                                class="form-control"
                                rows="2"
                                v-model="companyData.address"
                                />
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <hr class="mt-2">
                            <button @click="$emit('onClickSubmit')" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Submit
                            </button>
                            <button @click="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UpdateCompany',

    data: () => ({
        isOpen: false
    }),

    computed: {
        typeKeys() {
            return Object.keys(this.accountType)
        }
    },

    methods: {
        toggleModal () {
            this.isOpen = !this.isOpen
        },
        // onLogoUpload(event){
        //     this.companyData.logo=event.target.files[0]
        // },
        // onCoverPhotoUpload(event){
        //     this.companyData.cover_photo=event.target.files[0]
        // },
    },

    props: {
        loading: Boolean,
        title: {
            default: 'Company Edit'
        },
        companyData: Object,
        eventName: String
    }
}
</script>