<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ title }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">
        <div class="pb-2">
          <!-- form -->
          <form onsubmit="return false" novalidate="novalidate">
            <div class="row gy-1 px-2">
              <div class="col-12">
                <label class="form-label" for="chartName">Companies</label>
                <v-select
                    multiple
                    v-model="form.companies"
                    :options="companies"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
              <div class="col-12">
                <label class="form-label" for="email">Modules</label>
                <v-select
                    multiple
                    v-model="form.modules"
                    :options="modules"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
            </div>
            <div class="col-12 text-center">
              <hr class="mt-2">
              <button @click="$emit('onClickSubmit', form)" type="submit"
                      class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                Submit
              </button>
              <button @click="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import handleCompany from "../../../services/modules/company";

export default {
  name: "ModuleAddModal",

  data: () => ({
    isOpen: false,
    form: {
      companies: [],
      modules: [],
    },
    modules: [],
    companies: [],
  }),

  props: {
    title: {
      default: 'Add Modules',
    },
  },

  methods: {
    toggleModal() {
      this.isOpen = !this.isOpen
    },

    async getAllModules() {
      const res = await this.fetchAllModules();

      this.modules = res.data;
    },

    async getCompanyList() {
      const res = await this.fetchCompanyList();

      this.companies = res.data;
    },
  },

  setup() {
    const {
      fetchAllModules,
      fetchCompanyList,
    } = handleCompany();

    return {
      fetchAllModules,
      fetchCompanyList,
    }
  },

  async mounted() {
    await this.getAllModules();
    await this.getCompanyList();
  }
}
</script>